import { Subscription, Observable, Subject, combineLatest, zip, filter } from "rxjs";
import { DebugService as debug } from "@core/services/debug.service";
import { delay, } from "rxjs/operators";

export class Subscriptions {

    public static unsubscribe(subscriptions: Subscription[]) {
        //Unsubscribe from all subscriptions on this page
        let subscriptionCount = 0;
        for (let i in subscriptions) {
            if (subscriptions[i]) {
                subscriptions[i].unsubscribe();
                subscriptionCount++;
            }
        }
        debug.log("Unsubscribed from", subscriptionCount, "subscription" + (subscriptionCount > 1 ? "s" : ""));
    }

    /**
     * Subscribes to Mosaic services and handles client swaps.
     * @param { { [key: string]: ServiceMonitor }} services The specifications for the services to monitor.
     * @returns {Observable<{ [key: string]: any }>} A single observable which outputs an object containing the service observable outputs.
     */
    public static subscribeToServices(services: { [key: string]: ServiceMonitor }, options?: ServiceMonitorOptions): Subject<{ [key: string]: any }> {
        options = options || { verbose: false };
        let isFirstEmission = true;
        let serviceNames: string[] = Object.keys(services);
        let serviceObservables: Observable<any>[] = Object.values(services).map((s) => s.observable);
        let subject: Subject<any> = new Subject();
        let observable: Observable<any[]> = combineLatest(serviceObservables);

        //Delay is to let a subscriber subscribe before emitting
        let subscription: Subscription = observable
            .pipe(delay(10)) //Delay because otherwise the subscriptions don't work, used to be 50ms
            .pipe(filter(() => {

                //The first event always loads from the same client id
                if (isFirstEmission) {
                    if (options.verbose) debug.log(`Service subscription: First emmission.`)
                    return true
                }

                //Check that all of the client ids match
                let clientIds: string[] = [];
                for (let key in services) {
                    let serviceMonitor: ServiceMonitor = services[key];
                    if (!serviceMonitor.clientIdKey) continue
                    if ("undefined" == typeof serviceMonitor.service[serviceMonitor.clientIdKey]) {
                        if (options.verbose) debug.warn(`Service subscription: Client id check for service '${key}' does not exist.`)
                        continue
                    }
                    let clientId = serviceMonitor.service[serviceMonitor.clientIdKey];
                    if (!clientIds.includes(clientId)) clientIds.push(clientId);
                }

                if (clientIds.length > 1) {
                    if (options.verbose) debug.log(`Service subscription: Waiting for client ids ${clientIds} to align.`)
                    return false;
                }

                return true

            }))
            .subscribe((observableData) => {

                //Map the output to the proper name
                let output: { [key: string]: any } = {};
                for (let i in observableData) {
                    output[serviceNames[i]] = observableData[i];
                }

                //Output the observable data
                if (options.verbose) debug.log("Service subscription: Output data", output);
                subject.next(output)

                //Unsubscribe when no one is watching
                if (!subject.observers.length && !isFirstEmission) {
                    if (options.verbose) debug.log("Service subscription: Unsubscribed from services - ", serviceNames.join(", "));
                    if (subscription) subscription.unsubscribe();
                    return;
                }

                //This is no longer the first emission
                isFirstEmission = false;

            })

        return subject;
    }
}

interface ServiceMonitor {
    service: any;
    clientIdKey?: string;
    observable: Observable<any>;
}

interface ServiceMonitorOptions {
    verbose?: boolean;
}