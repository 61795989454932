
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


//Imports
import { AsyncStorage } from "@core/services/AsyncStorage";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { DebugService as debug } from "@core/services/debug.service";
import { NotificationSubscription } from '@core/data/notificationSubscription';

//Exports
export { AsyncStorage } from "@core/services/AsyncStorage";

@Injectable()
export class SettingsService {

    private _notificationsSubscriptions: AsyncStorage<NotificationSubscription>;
    private _targetClientId: string;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {

        //Notification Subscriptions
        this._notificationsSubscriptions = new AsyncStorage(this._http);
        this._notificationsSubscriptions._setAddFunction(this._save());
        this._notificationsSubscriptions._setElementComparisonFunction(this._notificationSubscriptionComparison);
        this._notificationsSubscriptions._setSaveActiveElementFunction(this._save());
        this._notificationsSubscriptions._setUpdateFunction(this._updateNotificationSubscriptions());


        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._notificationsSubscriptions.update({ clientId: client.clientId });
            });
    }

    public get activeNotificationSubscriptionsClientId(): string { return this._notificationsSubscriptions.clientId };

    public get notificationSubscriptions() { return this._notificationsSubscriptions };

    /**
     * Compares two notification subscription to determine if they are the same record.
     * @param a The first notification subscription.
     * @param b The second notification subscription.
     * @returns 
     */
    private _notificationSubscriptionComparison(a: NotificationSubscription, b: NotificationSubscription): boolean {
        //Compare the two subscription ids
        return a && b ? a.notificationSubscriptionId === b.notificationSubscriptionId : false;
    }

    /**
     * Creates a notification subscription or updates an existing subscription.
     * @param notificationSubscription The notification subscription.
     * @param options Additional save options.
     * @returns 
     */
    private _save(): (notificationSubscription: NotificationSubscription, options?: NotificationSubscriptionSaveOptions) => Observable<null | NotificationSubscription> {

        const self = this;

        return (notificationSubscription: NotificationSubscription, options?: NotificationSubscriptionSaveOptions) => {

            return new Observable(observer => {

                if (options?.unsubscribe) {

                    //Unsubscribe from the notification
                    self._http.post("notifications/unsubscribe", { notificationSubscriptionId: notificationSubscription?.notificationSubscriptionId }).toPromise()
                        .then((data: APIResponse) => {
                            if (0 == Object.keys(data.data).length || data.errors.length) {
                                throw new Error(data.errors?.pop() ?? "Unknown error has occurred.")
                            } else {
                                observer.next(null)
                            }
                        })
                        .catch(observer.error)

                } else {

                    //Subscribe to the notification or update the existing notification
                    self._http.post("notifications/subscribe", notificationSubscription).toPromise()
                        .then((data: APIResponse) => {
                            if (0 == Object.keys(data.data).length || data.errors.length) {
                                throw new Error(data.errors?.pop() ?? "Unknown error has occurred.")
                            } else {
                                observer.next(data.data)
                            }
                        })
                        .catch(observer.error)
                }

            })

        }

    }

    /**
     * Loads the list of active notification subscriptions.
     * @returns 
     */
    private _updateNotificationSubscriptions(): () => Observable<NotificationSubscription[]> {

        const self = this;

        return () => {

            debug.log("Loading notification subscriptions");

            return self._http.get("notifications/subscriptions/list").pipe(
                map((data: APIResponse) => {

                    //Convert the data
                    let notificationSubscriptions: NotificationSubscription[] = data?.data?.notificationSubscriptions ?? [];
                    debug.log("Loaded notification subscriptions:", notificationSubscriptions?.length ?? 0);

                    //Filter out notifications that are not associated with the active client
                    if (Array.isArray(notificationSubscriptions)) {
                        notificationSubscriptions = notificationSubscriptions.filter((notificationSubscription) => {
                            return notificationSubscription.subscriptionClientId === self._targetClientId
                        })
                    }
                    debug.log("Filtered notification subscriptions:", notificationSubscriptions.length);

                    return notificationSubscriptions;

                }));
        }

    }


}


export interface NotificationSubscriptionSaveOptions {
    unsubscribe?: boolean;
}