import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { DebugService as debug } from "@core/services/debug.service";

const ManifestUrl = ""

@Injectable({ providedIn: 'root' })
export class UpdateService {

    /** The frequency at which manifest requests are made.
     * @default 600000 Ten minutes. */
    private readonly _updateFrequency: number = 600000;

    private _updateTimer: NodeJS.Timeout;
    private _nextUpdateScheduledFor: number = Date.now() + this._updateFrequency;

    private _isUpdateAvailableSubject$: BehaviorSubject<boolean>;
    public isUpdateAvailable$: Observable<boolean>;

    /** The URL to poll for the manifest. */
    private _manifestUrl: string = ""

    constructor(
        private _http: HttpClient,
    ) {

        //Get the url for the manifest
        let url = new URL(window.location.href)
        this._manifestUrl = `${url.origin}/manifest.json`;

        this._isUpdateAvailableSubject$ = new BehaviorSubject<boolean>(false);
        this.isUpdateAvailable$ = this._isUpdateAvailableSubject$.asObservable();

        this._startUpdateTimer();

    }

    get isUpdateAvailable() { return this._isUpdateAvailableSubject$.value }

    async checkForUpdate(): Promise<void> {

        //Skip if there is already an update available
        if (this.isUpdateAvailable) return;

        //Load the manifest
        let manifest = <any>await firstValueFrom(
            this._http.get(this._manifestUrl, {
                headers: {
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    "Pragma": "no-cache",
                    "Expires": "0"
                }
            }).pipe(map((response: string | object) => {

                //Return the data
                try {
                    return "string" === typeof response ? JSON.parse(response) : response;
                } catch (e) {
                    return { version: "1.10.1" }
                }

            }))
        ).catch(debug.error)

        //Compare the version numbers
        if (manifest?.version && manifest.version > environment.APP_VERSION) {
            this._isUpdateAvailableSubject$.next(true);
        }

    }

    private _startUpdateTimer() {
        if (this._updateTimer) return

        this._updateTimer = setInterval(async () => {
            if (Date.now() > this._nextUpdateScheduledFor) {
                this._nextUpdateScheduledFor = Date.now() + this._updateFrequency;
                await this.checkForUpdate();
            }

        }, 10000)

    }

}

