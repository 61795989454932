import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatListOption } from '@angular/material/list';
import { AuthService } from '@core/services/auth/auth.service';
import { Location } from '@locations/locations.service';
import { SharingGroup } from '@locations/sharing-groups/sharing.service';
import { isMatch } from 'matcher';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent implements OnInit {

  @Input() public disabled: boolean = false;
  @Input() locations: Location[]
  @Input() selectedLocations?: string[] = ["*"];
  @Input() sharingGroups?: SharingGroup[] = []
  @Input() selectedSharingGroups?: string[] = ["*"];
  @Input() formGroup?: FormGroup;
  @Output() onSelection: EventEmitter<any> = new EventEmitter();

  private _availableLocations: Location[] = [];
  private _availableSharingGroups: SharingGroup[] = [];
  public filteredLocations: Location[] = [];
  public filteredSharingGroups: SharingGroup[] = [];

  public locationForm: FormGroup;

  constructor(
    private _auth: AuthService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    //Filter the locations by location access
    let availableLocations = this._auth.activeUserPermissions.locations ?? []
    this._availableLocations = this.locations.filter((location) => {
      return isMatch(location?.locationId, availableLocations)
    }).sort((locationA, locationB) => {

      //Sort by name desc
      const nameA = locationA?.data?.name?.trim(), nameB = locationB?.data?.name?.trim()
      if (nameA > nameB) return 1;
      if (nameA < nameB) return -1;

      //Sort by address desc
      const addressA = locationA?.data?.name?.trim(), addressB = locationB?.data?.name?.trim()
      if (addressA > addressB) return 1;
      if (addressA < addressB) return -1;

      return 0;

    })

    this.selectedSharingGroups = this.selectedSharingGroups?.filter((sharingGroupId) => "*" !== sharingGroupId) ?? []
    if (this.sharingGroups) {

      this._availableSharingGroups = this.sharingGroups
        .filter(this.filterEmptySharingGroups(this._availableLocations))
        .sort((sharingGroupA, sharingGroupB) => {

          //Sort by name desc
          const nameA = sharingGroupA?.name?.trim(), nameB = sharingGroupB?.name?.trim()
          if (nameA > nameB) return 1;
          if (nameA < nameB) return -1;

          return 0;

        })

    }

    this.filteredLocations = this._availableLocations
    this.filteredSharingGroups = this._availableSharingGroups

    if (this.formGroup) {

      //Validate form
      if (!(this.formGroup instanceof FormGroup))
        throw new Error("Invalid input type for location selection form.")


      //Validate form structure
      if (!(this.formGroup.get("locations") instanceof FormArray) || (this.sharingGroups && !(this.formGroup.get("sharingGroups") instanceof FormArray)))
        throw new Error("Invalid form structure for location selection form. Requires root level 'locations' and 'sharingGroups' form arrays.")

      this.locationForm = this.formGroup;


    } else {

      //Create new form
      this.locationForm = new FormGroup({
        locations: new FormArray(this.selectedLocations.map((locationId) => new FormControl(locationId))),
        sharingGroups: new FormArray(this.selectedSharingGroups.map((sharingGroupId) => new FormControl(sharingGroupId))),
      })

    }

    this._changeDetectorRef.detectChanges();
  }


  onSelectionChange(option: string, isChecked: boolean): void {

    const [targetArrayName, value] = option.split(":")

    if (isChecked) {

      //Clear all entries
      if ("*" === value) {
        (<FormArray>this.locationForm.controls[targetArrayName]).clear();
      }

      //Add to lists
      (<FormArray>this.locationForm.controls[targetArrayName]).push(new FormControl(value));


    } else {


      if ("*" === value) {

        //Clear all entries
        (<FormArray>this.locationForm.controls["locations"]).clear();
        (<FormArray>this.locationForm.controls["sharingGroups"]).clear();

      } else {

        //Remove from lists
        const locationIndex = (<FormArray>this.locationForm.controls[targetArrayName]).controls.findIndex(
          (control) => control.value === value
        );
        if (locationIndex >= 0)
          (<FormArray>this.locationForm.controls[targetArrayName]).removeAt(locationIndex);
      }

    }

    this.emitOnSelectionEvent();

  }

  emitOnSelectionEvent() {
    const formValue = this.locationForm.value;
    this.onSelection.emit(formValue)
  }

  applyFilter(searchValue: string) {
    let lowerCaseSearchValue = searchValue?.toLowerCase();
    this.filteredLocations = this._availableLocations.filter((location) => {
      const { address, city, prov, country, name } = location?.data ?? {};
      const fullAddress = `${address}, ${city}, ${prov}, ${country}`.toLowerCase();
      return fullAddress.includes(lowerCaseSearchValue) || name?.toLowerCase().includes(lowerCaseSearchValue)
    })

    //!You might think this is a good idea, it does not play nice with the search function
    // this.filteredSharingGroups = this._availableSharingGroups.filter(this.filterEmptySharingGroups(this.filteredLocations))
  }

  filterEmptySharingGroups(locations: Location[]): (sharingGroup: SharingGroup) => boolean {
    return (sharingGroup) => {
      //Filter out sharing groups that have no available locations
      let hasLocation = false;
      for (let locationId of sharingGroup?.locations) {
        for (let location of locations) {
          if (location.locationId !== locationId) continue;
          hasLocation = true;
          break
        }
        if (hasLocation) break
      }
      return hasLocation;
    }

  }

}
