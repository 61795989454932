import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '@core/data/location';
import { SharingGroup } from '@core/data/sharing';

@Pipe({
  name: 'locationInSharingGroup'
})
export class LocationInSharingGroupPipe implements PipeTransform {

  transform(locations: Location[], sharingGroup: SharingGroup | SharingGroup[], invert: boolean = false): Location[] {

    return locations.filter((location) => {
      let isIncluded = false;
      if (Array.isArray(sharingGroup)) {
        for (let sharingGroupItem of sharingGroup) {
          isIncluded = sharingGroupItem.locations.includes(location.locationId);
          if (isIncluded) break;
        }
      } else {
        isIncluded = sharingGroup.locations.includes(location.locationId);
      }
      return invert ? !isIncluded : isIncluded;
    });
  }

}
