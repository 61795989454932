<div class="app-masthead">

    <a routerLink="/" class="brand"><img src="/assets/img/logo.svg"></a>

</div>


<div class="app-menu" *ngIf="isLoaded">

    <!-- Not mobile -->
    <mat-nav-list>

        <mat-list-item *ngIf="!isManagementClient" class="menu-item" [routerLink]="['/customers']" routerLinkActive="active">
            <mat-icon matListIcon><i class="far fa-users"></i></mat-icon>
            <a matLine>Customers</a>
        </mat-list-item>

        <mat-list-item *ngIf="hasDashboardAccess" class="menu-item" [routerLink]="['/dashboard']" routerLinkActive="active">
            <mat-icon matListIcon><i class="far fa-columns"></i></mat-icon>
            <a matLine>Dashboard</a>
        </mat-list-item>

        <mat-list-item *ngIf="!isManagementClient" class="menu-item" [routerLink]="['/reports']" routerLinkActive="active">
            <mat-icon matListIcon><i class="far fa-analytics"></i></mat-icon>
            <a matLine>Reports</a>
        </mat-list-item>

        <mat-list-item class="menu-item" [routerLink]="['/campaigns']" routerLinkActive="active" *ngIf="!isProxy && !isManagementClient && hasWriteAccess">
            <mat-icon matListIcon><i class="far fa-mountains"></i></mat-icon>
            <a matLine>Campaigns</a>
        </mat-list-item>

        <mat-list-item class="menu-item" [routerLink]="['/locations']" routerLinkActive="active" *ngIf="!isManagementClient && hasWriteAccess">
            <mat-icon matListIcon><i class="far fa-warehouse"></i></mat-icon>
            <a matLine>Locations</a>
        </mat-list-item>

        <mat-list-item class="menu-item" [routerLink]="['/products']" routerLinkActive="active" *ngIf="!isManagementClient && hasWriteAccess">
            <mat-icon matListIcon><i class="far fa-box-full"></i></mat-icon>
            <a matLine>Products</a>
        </mat-list-item>

        <mat-list-item class="menu-item" [routerLink]="['/financial']" routerLinkActive="active" *ngIf="!isProxy && !isManagementClient && hasWriteAccess && isSuperAdmin">
            <mat-icon matListIcon><i class="far fa-piggy-bank"></i></mat-icon>
            <a matLine>Financial</a>
        </mat-list-item>

        <mat-list-item class="menu-item" [routerLink]="['/connections']" routerLinkActive="active" *ngIf="!isManagementClient && hasWriteAccess">
            <mat-icon matListIcon><i class="far fa-plug"></i></mat-icon>
            <a matLine>Connections</a>
        </mat-list-item>

        <!-- <mat-list-item class="menu-item" [routerLink]="['/marketing']" routerLinkActive="active">
            <mat-icon matListIcon>notifications_active</mat-icon>
            <a matLine >Marketing (Coming soon)</a>
        </mat-list-item> -->

        <mat-list-item class="menu-item" [routerLink]="['/admin/users']" routerLinkActive="active" *ngIf="hasWriteAccess && isSuperAdmin">
            <mat-icon matListIcon><i class="far fa-toolbox"></i></mat-icon>
            <a matLine>Admin</a>
        </mat-list-item>

        <mat-list-item class="menu-item" [routerLink]="['/settings']" routerLinkActive="active">
            <mat-icon matListIcon><i class="far fa-cog"></i></mat-icon>
            <a matLine>Settings</a>
        </mat-list-item>

        <mat-list-item class="menu-item logout" (click)="logout()">
            <mat-icon matListIcon><i class="far fa-sign-out-alt"></i></mat-icon>
            <a matLine>Logout</a>
        </mat-list-item>

    </mat-nav-list>
    <div class="version">v{{ versionNumber}}</div>
</div>