
import { combineLatest, Observable, BehaviorSubject, firstValueFrom } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//Imports
import { AsyncStorage } from "@core/services/AsyncStorage";
import { Client } from '@core/data/client';
import { APIResponse } from '@shared/types.barrel';
import { DebugService as debug } from "@core/services/debug.service";
import { AuthService } from '@core/services/auth/auth.service';
import { LocalHistoryService } from '@core/services/local-history/local-history.service';

//Exports
export { AsyncStorage } from "@core/services/AsyncStorage";
export { Client, App, ClientData, ContactObject, Address } from '@core/data/client';

@Injectable({ providedIn: 'root' })
export class ClientsService {

    /** 
     * The client id of the client that the active user belongs to. 
     * 
     * Pulled from the user data.
     */
    private _nativeClientId: string;
    private _clients: AsyncStorage<Client>;
    private _clientLock: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _nativeClient: BehaviorSubject<Client> = new BehaviorSubject(null);

    private _initialized: boolean = false;

    constructor(
        private _http: HttpClient,
        private _localHistory: LocalHistoryService,
        private _auth: AuthService
    ) {
        this.initialize();
    }

    public initialize() {

        //Set the initialization state
        if (this._initialized) return;
        this._initialized = true;

        //Set the native client id
        /* This can be on initialization and not as a subcription because Admin portal doesn't allow 
        for logging into different clients without swapping the url */
        this._nativeClientId = this._auth.activeClient?.clientId;

        this._clients = new AsyncStorage(this._http);
        this._clients._setAddFunction(this._addClient);
        this._clients._setElementComparisonFunction(this._clientComparison);
        this._clients._setSaveActiveElementFunction(this._saveClient);
        this._clients._setUpdateFunction(this._updateClients());

        //Set the default client if we have the history
        let defaultClientId: string = this._localHistory.history?.clientId || "";
        if (defaultClientId)
            this._clients._setDefaultElement({ clientId: defaultClientId })

        this._clients.update();

        //Update the last accessed client on each swap
        this._clients.onActiveElementChange().subscribe((client: Client) => { this._localHistory.history = { clientId: client.clientId }; })
    }

    public get nativeClient(): Client | null { return this._nativeClient.value };
    public get clients() { return this._clients };
    public get clientLock() { return this._clientLock };

    public enableClientSwapping() { this._clientLock.next(false) }
    public toggleClientSwapping() { this._clientLock.value ? this._clientLock.next(false) : this._clientLock.next(true) };
    public disableClientSwapping() { this._clientLock.next(true) }

    private _addClient(client: Client): Observable<any> {
        debug.log("Adding client");
        return this._http.post("clients/addClient", client).pipe(
            map((res: APIResponse) => {
                return { clientId: res.data ? res.data["ClientId"] : null }
            }));
    }

    private _clientComparison(a: Client, b: Client): boolean {
        //Compare the two client IDs
        return a && b ? a.clientId === b.clientId : false;
    }

    private _saveClient(client: Client, options: { active?: boolean, app?: boolean, client?: boolean }): Observable<any> {
        let data = { clientId: client.clientId };

        let requests: Observable<any>[] = [];

        //Update active mode
        if (options.active) {
            requests.push(this._http.post('clients/setActiveMode', {
                clientId: client.clientId,
                active: client.active
            }).pipe(map((res: APIResponse) => {
                return res.data;
            })));
        }

        //Update app
        if (options.app) {
            requests.push(this._http.post('clients/updateApp', {
                clientId: client.clientId,
                appData: client.appData
            }).pipe(map((res: APIResponse) => {
                return res.data;
            })));
        }

        //Update client data
        if (options.client) {
            requests.push(this._http.post('clients/updateClient', {
                clientId: client.clientId,
                clientData: client.clientData,
                appName: client.appName,
                appDownload: client.appDownload
            }).pipe(map((res: APIResponse) => {
                return res.data;
            })));
        }

        //Save all applicable in order
        if (requests.length) {

            //Return response only from final request
            return combineLatest.apply(this, requests);

        } else {

            //Complete with an empty observable
            return new Observable(observer => { observer.next(null); })

        }

    }

    private _updateClients(): () => Observable<Client[]> {
        return () => {
            debug.log("Updating clients");

            return this._http.post("clients/get", null).pipe(
                map((data: APIResponse) => {

                    //Grab the data
                    let clients: Client[] = data?.data || [];

                    //Add type if the client is missing it
                    clients = clients.map((client) => {
                        client.type = client.type || "standard";
                        return client
                    })

                    //Sort alphabetically
                    clients.sort((leftSide: Client, rightSide: Client): number => {
                        let leftClientName = leftSide?.clientData?.clientName || "~"; // ~ Comes immediately after z in sorting
                        let rightClientName = rightSide?.clientData?.clientName || "~";
                        if (leftClientName < rightClientName) return -1
                        if (leftClientName > rightClientName) return 1
                        return 0;
                    });

                    //Check if current client is an option as a client
                    let nativeClientIndex: number = clients.findIndex((client: Client) => {
                        return client.clientId === this._nativeClientId;
                    });

                    //Shift cwc to the start
                    if (nativeClientIndex >= 0) {
                        this._nativeClient.next(clients[nativeClientIndex])
                        clients = [].concat([clients[nativeClientIndex]], clients)
                        clients.splice(nativeClientIndex + 1, 1)
                    }

                    return clients;
                }));
        }

    }
   
}
