import { Component, Input, OnInit } from '@angular/core';
import { BlockedStatus } from '@shared/enums/BlockedStatus';
import { UserDevice } from '@shared/models/user';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-device',
  templateUrl: './customer-device.component.html',
  styleUrls: ['./customer-device.component.scss']
})
export class CustomerDeviceComponent implements OnInit {

  @Input() device: (UserDevice & { blockedStatus: BlockedStatus }) = null;

  /** The action that is performed by the device action. */
  @Input() onAction: (device: UserDevice) => Promise<void> = null;

  /** If user should be allowed to perform the device action. */
  @Input() allowAction: boolean = false;

  /** If the action is temporarily prevented. */
  @Input() isDisabled: boolean = false;

  constructor() { }

  ngOnInit(): void { }


  /**
   * Identifies if the device is an Apple device.
   * @param osType The operating system of the device.
   * @returns
   */
  public isAppleDevice(osType: string): boolean {
    return ['ios', 'ipados'].includes(osType?.toLowerCase())
  }

  /**
   * Identifies if the device is an Android device.
   * @param osType The operating system of the device.
   * @returns
   */
  public isAndroidDevice(osType: string): boolean {
    return ['android'].includes(osType?.toLowerCase())
  }

  /**
   * Identifies if the device is an unknown device.
   * @param osType The operating system of the device.
   * @returns
   */
  public isUnknownDevice(osType: string): boolean {
    return !this.isAndroidDevice(osType) && !this.isAppleDevice(osType)
  }

  /**
   * Converts a unix timestamp to a string relative to now.
   *
   * Eg. "an hour ago"
   * @param timestamp The unix timestamp.
   * @returns
   */
  public toFromNowDate(timestamp: number): string {
    return moment(timestamp).fromNow()
  }

  public async deviceAction(): Promise<void> {
    if (this.onAction)
      await this.onAction(this.device)
  }


  /**
   * Gets the colour for the device action button.
   * @returns
   */
  public getColour(): "primary" | "warn" {
    if (BlockedStatus.Blocked === this.device?.blockedStatus)
      return "primary"
    return "warn"
  }

}
