import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, NEVER } from "rxjs";
import { DebugService as debug } from "@core/services/debug.service";
import { Errors } from '@shared/lib/Errors';
import { scan, filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

export class AsyncStorage<T> {
    private _checkFirstForNew: boolean = true;
    private _activeClientId: BehaviorSubject<string> = new BehaviorSubject(null);
    private _activeElementIndex: number = -1;
    private _activeElement: BehaviorSubject<T> = new BehaviorSubject(null);
    private _data: BehaviorSubject<T[]> = new BehaviorSubject(null);
    private _errors: BehaviorSubject<AsyncStorageError> = new BehaviorSubject(null);
    private _addFunction: (data: any, options?: any) => Observable<any>;
    private _elementComparisonFunction: (a: T, b: T) => boolean = (a, b) => { return a === b };
    private _saveActiveElementFunction: (data: any, options?: any) => Observable<T | void>;
    private _updateFunction: (options?: any) => Observable<T[]>;
    private _newElementCache: T;
    private _defaultElement: any;
    private _isDummy: boolean;
    private _isUpdating: boolean = false;
    private _contentLockFunction: (data: any) => boolean = () => true;

    constructor(
        private _http: HttpClient
    ) { }

    public reset(): void {
        this.setData(null);
        if (this._updateFunction) this.update();
    }

    public _setAddFunction(observable: (data: any, options?: any) => Observable<any>) {
        this._addFunction = observable;
    }

    public _setElementComparisonFunction(func: (a: T, b: T) => boolean) {
        this._elementComparisonFunction = func;
    }

    public _setContentLockFunction(func: (data: any) => boolean) {
        this._contentLockFunction = func;
    }

    public _setSaveActiveElementFunction(observable: (data: any, options?: any) => Observable<T | void>) {
        this._saveActiveElementFunction = observable;
    }

    public _setUpdateFunction(observable: (options?: any) => Observable<T[]>) {
        this._updateFunction = observable;
    }

    public _setDefaultElement(element: any) {
        this._defaultElement = element;
    }

    public get clientId(): string { return this._activeClientId.value }

    public add(data: any, options?: any): void {

        //Check if we have an add function
        if ("function" != typeof this._addFunction) {
            return debug.warn("AsyncStorage.add() has been executed but no add function has been provided.");
        };

        //Execute the add function
        var subscription = this._addFunction(data, options).subscribe((res: any) => {

            //If we have an object
            if (res) {
                this._newElementCache = res;
            }

            //Update the storage if available
            if (this._updateFunction) this.update();

            //Unsubscribe from the subscription
            subscription.unsubscribe();

        }, (err) => {

            //Log the error
            debug.error("Error adding to async storage:", Errors.httpToMosaic(err));

            //Throw the error
            this._errors.next({
                message: "Failed to add to async storage",
                code: "AddingException",
                function: "Add()",
                rawError: err,
                timeStamp: (new Date()).toISOString()
            })

            //Unsubscribe from the subscription
            if (subscription) subscription.unsubscribe();
        })

    }

    public addDummy(data: any): void {

        //Add temporary value to the data array
        if (Array.isArray(this._data.value)) {
            this._data.value.push(data);
            this.setActiveElementById(this._data.value.length - 1);
            this._isDummy = true;
        }

    }

    public deleteElementAtIndex(index: number): void {
        this._data.value.splice(index, 1);
        if (index <= this._activeElementIndex) this.setActiveElementById(index - 1)
    }


    //On active element change
    public onActiveElementChange() {
        return this._activeElement.asObservable().pipe(
            scan((acc, elem) => { return elem }),
            filter(data => null !== data),
            distinctUntilChanged())
    }


    //On data update
    public onUpdate(options: OnUpdateOptions = {}) {
        let dataObservable = this._data.asObservable()
        return dataObservable.pipe(
            switchMap(() => {
                return this._isUpdating ? NEVER : dataObservable
            }),
            scan((acc, elem) => { return elem }),
            filter(data => null !== data),
            true === options?.allUpdates ? map(data => data) : distinctUntilChanged(),
            switchMap(() => {
                let isContentLock = !this._contentLockFunction(this._data.value)
                if (isContentLock) debug.log("Content is locked")
                return isContentLock ? NEVER : dataObservable
            }))
    }

    //On active element change
    public onError() {
        return this._errors.asObservable().pipe(
            scan((acc, elem) => { return elem }),
            filter(data => null !== data),
            distinctUntilChanged())
    }

    //Gets the data
    get activeElement() {
        return this._activeElement.value;
    }

    //Gets the data
    get data(): BehaviorSubject<T[]> {
        return this._data;
    }

    //Sets the data
    setData(value: T[] | null) {

        //Check if the data is an array
        if (Array.isArray(value)) this._data.next(value);
        //Set the latest to null
        else if (value == null) {
            this._data.next(null);
            this._activeElement.next(null);
            return;
        }
        //Make the data into an array and store
        else this._data.next([value]);

        //Check if we have a new element
        if (this._newElementCache) {

            //Prepare an index to store the
            let foundIndex = -1;

            //Loop through to find the element
            for (let i = 0; i < this._data.value.length; i++) {

                //Check if the data value equals the cached new value
                if (this._elementComparisonFunction(this._newElementCache, this._data.value[i])) {

                    //Copy the index and leave the loop
                    foundIndex = i;
                    break;
                }

            }

            //Check if we found a value
            if (foundIndex) {

                //Change the index
                this._activeElementIndex = foundIndex;
                this.setActiveElementById(this._activeElementIndex);

            } else {

                //Reset the active element
                this._activeElementIndex = -1;
                this._activeElement.next(null);

            }

            //Remove so we don't check against it next time
            this._newElementCache = null;

            //We're done here
            return;

            //Check if the active element has moved from the current index
        } else if (!this._elementComparisonFunction(this._activeElement.value, this._data.value[this._activeElementIndex])) {

            //Find the index of the active element
            let index = this.indexOf(this._activeElement.value)

            //Check if we found a value
            if (index >= 0) {

                //Change the index
                this._activeElementIndex = index;

            } else {

                //Reset the active element
                this._activeElementIndex = -1;
                this._activeElement.next(null);

            }


        }

    }

    public save(options?: any): Promise<void> {

        //Set a default for options
        options = options || {};
        //Check if we have an add function
        if ("function" != typeof this._saveActiveElementFunction) {
            debug.warn("AsyncStorage.save() has been executed but no save function has been provided.");
            return Promise.resolve();
        };

        //Check if we have an add function
        if (0 > this._activeElementIndex && !options["ignoreActiveElement"]) {
            debug.error("AsyncStorage.save() has been executed but there is no active element.");
            return Promise.resolve();
        };

        return new Promise((resolve, reject) => {
            //Execute the add function
            var subscription = this._saveActiveElementFunction(this._activeElement.value, options).subscribe((res) => {

                //If we have an object
                if (this._isDummy && this._data.value.length - 1 == this._activeElementIndex) {
                    if (res) {
                        this._newElementCache = res;
                    } else {
                        this._newElementCache = this._activeElement.value;
                    }
                    debug.log("AsyncStorage.save() caching dummy element:", this._newElementCache);
                }

                //Update the storage if available
                if (this._updateFunction) this.update();

                //Unsubscribe from the subscription
                if (subscription) subscription.unsubscribe();

                return resolve();

            }, (err) => {

                //Log the error
                debug.error("Error saving async storage:", Errors.httpToMosaic(err));

                //Throw the error
                this._errors.next({
                    message: "Failed to save to async storage",
                    code: "SavingException",
                    function: "Save()",
                    rawError: err,
                    timeStamp: (new Date()).toISOString()
                })

                //Unsubscribe from the subscription
                if (subscription) subscription.unsubscribe();

                return resolve();
            })
        });

    }


    //Sets the active element
    public setActiveElement(data: any) {

        if (null == this._data.value)
            return debug.error("AsyncStorage.setActiveElement() has been executed but no data is present.")

        //Grab the selected element
        let index = this._data.value.map((val) => {
            return JSON.stringify(val)
        }).indexOf(JSON.stringify(data));

        //Select by the index
        this.setActiveElementById(index);

    }

    //Sets the active element
    public setActiveElementById(index: any) {

        //Grab the selected element
        let tempValue = this._data.value[index];

        //Check if the element is has a value
        if ("undefined" != typeof tempValue) {

            //Set the active element
            this._activeElementIndex = index;
            this._activeElement.next(tempValue)

        }
    }

    public indexOf(element: any) {

        //Prepare an index to store the
        let index = -1;

        //Loop through to find the element
        for (let i = 0; i < this._data.value.length; i++) {

            //Check if the data value equals the cached new value
            if (this._elementComparisonFunction(element, this._data.value[i])) {

                //Copy the index and leave the loop
                index = i;
                break;
            }

        }

        return index

    }

    public update(options?: any): void {

        //Check if we have an update function
        if ("function" != typeof this._updateFunction) {
            return debug.warn("AsyncStorage.update() has been executed but no update function has been provided.");
        };

        //Check the update flag
        if (this._isUpdating) return;
        this._isUpdating = true;

        //Execute the update function
        var subscription = this._updateFunction(options).subscribe((data: T[]) => {

            this._isUpdating = false;

            //Update the client id if available
            if (options?.clientId)
                this._activeClientId.next(options?.clientId)

            //Set the data
            this.setData(data);

            //After updating there are no dummies
            this._isDummy = false;

            if (this._activeElementIndex < 0) {
                let index: number = this._defaultElement ? this.indexOf(this._defaultElement) : 0;

                //Set the active element if there isn't one
                if (index >= 0) this.setActiveElementById(index);
                else this.setActiveElementById(0);

            } else {
                //Set the active element if there is one, prevents element caching
                this.setActiveElementById(this._activeElementIndex)
            }

            //Unsubscribe from the subscription
            if (subscription) subscription.unsubscribe();

        }, (err) => {

            //Log the error
            debug.error("Error updating async storage:", Errors.httpToMosaic(err));

            //Throw the error
            this._errors.next({
                message: "Failed to update async storage",
                code: "UpdateException",
                function: "update",
                rawError: err,
                timeStamp: (new Date()).toISOString()
            })

            // this._data.error({
            //     message: "Failed to update async storage",
            //     code: "UpdateException",
            //     function: "update",
            //     rawError: err,
            //     timeStamp: (new Date()).toISOString()
            // });

            this._isUpdating = false;

            //Unsubscribe from the subscription
            if (subscription) subscription.unsubscribe();
        })
    }

}


export interface AsyncStorageError {
    code: AsyncErrorCodes;
    message: string;
    function: string;
    rawError: any;
    timeStamp: string;
}

export interface OnUpdateOptions {
    allUpdates?: boolean;
}

export type AsyncErrorCodes = "AddingException" | "SavingException" | "UpdateException"
