import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { map, switchMap } from 'rxjs/operators';

import { AsyncStorage } from "@core/services/AsyncStorage";
import { Transaction } from "@core/data/transaction";
import { ReportTransaction, SubscriptionStatistics, ReportTransactionData, ReportsTransactionsResponse, SummaryData } from "@core/data/reports";
import { SubscriptionSalesReport, SubscriptionSales } from "@core/data/subscriptionSalesReport";
import { SubscriptionSalesDetailReport } from "@core/data/subscriptionSalesDetailReport";
import { SingleWashSaleReport, SingleWashSale } from "@core/data/singleWashSaleReport";
import { APIResponse } from '@shared/types.barrel';
import { ClientsService, Client } from '@admin/clients/clients.service';
import { Dates } from '@shared/lib/Dates';
import { DebugService as debug } from "@core/services/debug.service";
import { Reports } from '@shared/lib/Reports';
import { Objects } from '@carwashconnect/cwc-core-js';
import { AuthService } from '@core/services/auth/auth.service';
import { WashActivations } from '@core/data/washActivationsReport';

export {
    ReportTransactionData, ReportTransaction, SubscriptionStatistics, Reports, SummaryData, SingleWashSaleReport, SingleWashSale,
    SubscriptionSalesReport, SubscriptionSales, SubscriptionSalesDetailReport
};

const SINGLESALE_TRANSACTION_WHITELIST: string[] = ["direct"];
const SUBSCRIPTION_TRANSACTION_WHITELIST: string[] = ["subscriptioncode", "subscription", "renewal", "subscriptionupgrade"];
export const VALID_STATUSES = ["success", "refunded"]

@Injectable()
export class ReportsService {

    private _transactionsCache: DateCache;

    // user pagination
    private _page: number = 1;
    private _pageLength: number = 10;
    private _transactionType: string[] = [];

    // subscriptionDetail pagination
    private _stdPage: number = 1;
    private _stdPageLength: number = 10;
    private _stdCollectionType: string = "NEW_SUBS";

    private _filter: string = "";

    private _percentLoaded: BehaviorSubject<number> = new BehaviorSubject(0);

    private _transactions: AsyncStorage<ReportTransactionData>;
    private _transactionSubscriptionCount: number = 0;
    private _singleSaleTransactions: AsyncStorage<SingleWashSaleReport>;
    private _singleSaleSubscriptionCount: number = 0;
    private _subscriptionTransactions: AsyncStorage<SubscriptionSalesReport>;
    private _subscriptionSubscriptionCount: number = 0;
    private _subscriptionTransactionDetails: AsyncStorage<SubscriptionSalesDetailReport>;
    private _subscriptionDetailsSubscriptionCount: number = 0;


    private _subscriptionSummaryClientCache: { [clientId: string]: { [date: string]: SubscriptionStatistics } } = {};

    private _washActivations: AsyncStorage<WashActivations>

    private _filters: TransactionFilters = {};
    private _activeClientId: string;

    private _lastTimezone: string = "utc";
    public _lastEndTime: string;
    public _lastStartTime: string;

    public getTimestamp: (transaction: ReportTransaction, isUIElement?: boolean) => number = (transaction: ReportTransaction, isUIElement = false) => {
        if (!isUIElement && transaction.completedOn) return transaction.completedOn;
        switch (transaction.transactionType.toLowerCase()) {
            case "renewal":
                return transaction.fulfillOn;
            default:
                return transaction.createdOn;
        }
    }

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        let endDate = new Date();
        let startDate = Dates.getDayStart(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 7));

        this._lastStartTime = startDate.toISOString();
        this._lastEndTime = endDate.toISOString();

        this._initializeStorage();
    }

    private _initializeStorage() {

        //Prepare the transaction cache
        this._transactionsCache = new DateCache("transactionCache", true);
        this._transactionsCache.setTimeStampFunction(this.getTimestamp);

        //Prepare transaction storage
        this._transactions = new AsyncStorage(this._http);
        this._transactions._setUpdateFunction(this._updateTransactions());

        this._singleSaleTransactions = new AsyncStorage(this._http);
        this._singleSaleTransactions._setUpdateFunction(this._updateSingleSalesTransactions());

        this._subscriptionTransactions = new AsyncStorage(this._http);
        this._subscriptionTransactions._setUpdateFunction(this._updateSubscriptionSalesTransactions());

        this._washActivations = new AsyncStorage(this._http);
        this._washActivations._setUpdateFunction(this._updateWashActivations());

        this._subscriptionTransactionDetails = new AsyncStorage(this._http);
        this._subscriptionTransactionDetails._setUpdateFunction(this._updateSubscriptionSalesTransactionDetails());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._activeClientId = client.clientId;
                this._transactionsCache.changeClients(this._activeClientId);

                this.refresh()
            });

    }

    public get percentLoaded() { return this._percentLoaded }

    public get transactions() {
        if (!this._transactionSubscriptionCount) {
            this._transactionSubscriptionCount++;
            this._transactions.update();
        }
        return this._transactions
    };
    public get subscriptionSales() {
        if (!this._subscriptionSubscriptionCount) {
            this._subscriptionSubscriptionCount++;
            this._subscriptionTransactions.update();
        }
        return this._subscriptionTransactions
    };
    public get washActivations() {
        return this._washActivations
    };
    public get subscriptionSaleDetails() {
        if (!this._subscriptionDetailsSubscriptionCount) {
            this._subscriptionDetailsSubscriptionCount++;
            this._subscriptionTransactionDetails.update();
        }
        return this._subscriptionTransactionDetails
    };
    public get singleSales() {
        if (!this._singleSaleSubscriptionCount) {
            this._singleSaleSubscriptionCount++;
            this._singleSaleTransactions.update();
        }
        return this._singleSaleTransactions
    };
    public get filter() { return this._filters };
    public get timeRange(): { start: string, end: string } { return { start: this._lastStartTime, end: this._lastEndTime } };
    public get subscriptionSummary(): { [date: string]: SubscriptionStatistics } { return this._subscriptionSummaryClientCache[this._activeClientId] };
    public get _clientId(): string { return this._activeClientId || this._auth.activeClient?.clientId; }


    public refresh(): void {
        if (this._transactionSubscriptionCount > 0) this._transactions.update();
        if (this._singleSaleSubscriptionCount > 0) this._singleSaleTransactions.update();
        if (this._subscriptionSubscriptionCount > 0) this._subscriptionTransactions.update();
        if (this._subscriptionDetailsSubscriptionCount > 0) this._subscriptionTransactionDetails.update();
    }

    public paginateTransactions(page: number, pageLength: number, transactionType: string[], filter: string, locations: string[], startDate: string, endDate: string, timezone: string = "utc") {
        this._page = page || this._page;
        this._pageLength = pageLength || this._pageLength;
        this._transactionType = transactionType;
        this._filter = filter;

        this._filters.locations = locations;

        this._lastStartTime = startDate;
        this._lastEndTime = endDate;
        this._lastTimezone = timezone;

        this._transactions.update();
    }

    private getTransactions(inputData: { [key: string]: any }): Promise<ReportTransactionData[]> {

        let getTransactionApiCall = (inputs): Promise<ReportTransactionData[]> => {
            return new Promise(async (resolve, reject) => {

                inputs.timezone = this._lastTimezone.toUpperCase();
                var subscription = this._http.post("reports/getTransactionReport", Objects.trim(inputs))
                    .subscribe(async (data: APIResponse) => {

                        let reportTransaction: ReportTransactionData = data.data;

                        if (reportTransaction.LastEvaluatedKey && !inputs.batchQuantity) {
                            inputs.lastEvaluatedKey = reportTransaction.LastEvaluatedKey;
                            let nextDataSet = await getTransactionApiCall(inputs);
                            reportTransaction.Items = [].concat(reportTransaction.Items, nextDataSet[0].Items);
                        }

                        return resolve([reportTransaction]);

                    }, reject);
            });

        }

        return getTransactionApiCall(inputData);

    }

    public getTransactionsAndTrackerProducts(inputData: { [key: string]: any }): Promise<ReportsTransactionsResponse> {

        let getTransactionApiCall = (inputs): Promise<ReportsTransactionsResponse> => {
            return new Promise(async (resolve, reject) => {

                var subscription = this._http.post("reports/transactions", inputs)
                    .subscribe(async (data: APIResponse) => {

                        let reportTransaction: ReportsTransactionsResponse = data.data;

                        return resolve(reportTransaction);

                    }, reject);
            });

        }

        return getTransactionApiCall(inputData);

    }

    private _updateTransactions(): (garbage: any) => Observable<ReportTransactionData[]> {
        let self = this;

        return (options: { startDate?: string, endDate?: string } = {}) => {

            //Prepare the inputs
            let inputs: { [key: string]: any } = {
                clientId: self._clientId,
                locations: this._filters.locations,
                startDate: this._lastStartTime,
                endDate: this._lastEndTime,
                page: self._page,
                transactionType: self._transactionType?.length ? self._transactionType : ["ss", "sub", "ref", "x", "pts"],
                batchQuantity: self._pageLength,
                filter: self._filter
            }

            return new Observable((observer) => {
                let promises: Promise<ReportTransactionData[]>[] = [];

                promises.push(self.getTransactions(inputs));

                Promise.all(promises).then((data: ReportTransactionData[][]) => {
                    observer.next(data[0]);
                });
            });

        }
    }

    public async getTransactionCsvExport(): Promise<ReportTransactionData[]> {
        //Prepare the inputs
        let inputs: { [key: string]: any } = {
            clientId: this._clientId,
            locations: this._filters.locations,
            startDate: this._lastStartTime,
            endDate: this._lastEndTime,
            transactionType: this._transactionType?.length ? this._transactionType : ["ss", "sub", "ref", "x", "pts"],
            filter: this._filter
        }
        if (Array.isArray(inputs.transactionType) && inputs.transactionType.indexOf("sub") >= 0) {
            inputs.transactionType.push("subCode")
        }
        return await this.getTransactions(inputs);
    }

    public filterWashSales(locations: string[], startDate: string, endDate: string, timezone: string = "utc") {
        this._filters.locations = locations;
        this._lastStartTime = startDate;
        this._lastEndTime = endDate;
        this._lastTimezone = timezone;
        this._singleSaleTransactions.update();
    }

    private _updateSingleSalesTransactions(): (garbage: any) => Observable<SingleWashSaleReport[]> {
        let self = this;
        return () => {


            return this._http.post("reports/getSingleWashSaleReport", {
                clientId: this._clientId,
                locations: this._filters.locations,
                startDate: this._lastStartTime,
                endDate: this._lastEndTime,
                timezone: this._lastTimezone.toUpperCase()
            }).pipe(
                map((data: APIResponse) => {

                    // return all locations
                    return data.data;

                }));
        }
    }

    public updateSubscriptionTransactions(locations: string[], startDate: string, endDate: string, timezone: string = "utc") {
        this._filters.locations = locations;
        this._lastStartTime = startDate;
        this._lastEndTime = endDate;
        this._lastTimezone = timezone;
        this._subscriptionTransactions.update();
    }

    public updateWashActivations(locations: string[], startDate: string, endDate: string, timezone: string = "utc") {
        this._filters.locations = locations;
        this._lastStartTime = startDate;
        this._lastEndTime = endDate;
        this._lastTimezone = timezone;
        this._washActivations.update();

    }

    private _updateSubscriptionSalesTransactionDetails(): (garbage: any) => Observable<SubscriptionSalesDetailReport[]> {
        let self = this;
        return () => {


            return this._http.post("reports/getSubscriptionDetailReport", {
                clientId: this._clientId,
                locations: this._filters.locations,
                startDate: this._lastStartTime,
                endDate: this._lastEndTime,
                collectionType: this._stdCollectionType,
                batchQuantity: this._stdPageLength,
                page: this._stdPage,
                timezone: this._lastTimezone.toUpperCase()
            }).pipe(
                map((data: APIResponse) => {

                    // return all locations
                    return data.data;

                }));
        }
    }

    public updateSubscriptionTransactionDetails(locations: string[], startDate: string, endDate: string, collection: string, page: number, pageLength: number, timezone: string = "utc") {
        this._filters.locations = locations;
        this._lastStartTime = startDate;
        this._lastEndTime = endDate
        this._stdPage = page || this._stdPage;
        this._stdPageLength = pageLength || this._stdPageLength;
        this._stdCollectionType = collection || this._stdCollectionType;
        this._lastTimezone = timezone;
        this._subscriptionTransactionDetails.update();
    }

    public async getSubscriptionDetailTransactionCsvExport(collectionType: string) {
        const MAX_PAGE = 1000;
        let transactionDetails = [];
        let dataParams = {
            clientId: this._clientId,
            locations: this._filters.locations,
            startDate: this._lastStartTime,
            endDate: this._lastEndTime,
            collectionType: collectionType,
            batchQuantity: 100,
            page: 1,
            timezone: this._lastTimezone.toUpperCase()
        };

        let totalPages = 0;
        let currentPage = 0;

        do {
            let response = await this._http.post("reports/getSubscriptionDetailReport", dataParams).toPromise();
            let data = response["data"];
            totalPages = data.TotalPages || 0;
            currentPage = data.Page || 0;

            if (Array.isArray(data.Transactions) && data.Transactions.length > 0) {
                transactionDetails = transactionDetails.concat(data.Transactions);
            }

            dataParams.page += 1;
        } while (dataParams.page <= MAX_PAGE && currentPage <= totalPages && currentPage != dataParams.page && totalPages > 0);

        return transactionDetails;
    }

    private _updateSubscriptionSalesTransactions(): (garbage: any) => Observable<SubscriptionSalesReport[]> {
        let self = this;

        return () => {
            return this._http.post("reports/getSubscriptionSaleReport", {
                clientId: this._clientId,
                locations: this._filters.locations,
                startDate: this._lastStartTime,
                endDate: this._lastEndTime,
                timezone: this._lastTimezone.toUpperCase()
            }).pipe(
                map((data: APIResponse) => {

                    // return all locations
                    return data.data;

                }));
        }
    }

    private _updateWashActivations(): (garbage: any) => Observable<WashActivations[]> {
        let self = this;
        return () => {
            return this._http.post("cwfController/get", {
                clientId: this._clientId,
            }).pipe(
                switchMap((cwfControllerData: APIResponse) => {
                    let controllers = Object.keys(cwfControllerData.data.data)
                    if (this._filters?.locations?.length) {
                        controllers = [];
                        for (let macAddress in cwfControllerData.data.data) {
                            let obj = cwfControllerData.data.data[macAddress]
                            if (this._filters.locations.includes(obj?.locationId)) controllers.push(obj?.macAddress)
                        }
                    }

                    return this._http.post("reports/getSingleSourceOfTruthReport", {
                        clientId: this._clientId,
                        controllers: controllers,
                        startDate: this._lastStartTime,
                        endDate: this._lastEndTime,
                        timezone: this._lastTimezone,
                    })
                }),
                map((data: APIResponse) => {
                    return data.data;
                })
            )
        }
    }

    public refund(transaction: Pick<ReportTransaction, "transactionId">, options: RefundOptions = {}): Promise<void> {
        return new Promise((resolve, reject) => {

            //Prepare the inputs
            let inputs: { [key: string]: any } = {
                clientId: this._clientId,
                transactionId: transaction.transactionId
            }

            //Optional parameters
            if (options.refundAmount) inputs["amount"] = options.refundAmount
            if ("undefined" !== typeof options.terminateSubscription) inputs["terminateSubscription"] = options.terminateSubscription
            if (options.terminationReason) inputs["terminationReason"] = options.terminationReason

            //Execute the refund
            this._http.post("transactions/refund", inputs)
                .subscribe((response: APIResponse) => {

                    //Check if there are errors
                    if (response.errors.length) return reject(response.errors.pop());

                    //Add the transaction to the list
                    let originalTransaction: Transaction = (response.data || {}).original;
                    let refundTransaction: Transaction = (response.data || {}).refund;

                    debug.log("Original transaction:", originalTransaction)
                    debug.log("Refunded transaction:", refundTransaction)

                    if (!(originalTransaction.transactionId && this._clientId == originalTransaction.clientId)) throw "Invalid original transaction."
                    if (!(refundTransaction.transactionId && this._clientId == refundTransaction.clientId)) throw "Invalid refund transaction."

                    this._http.post("reports/getTransactionReport", { ...inputs, timezone: this._lastTimezone.toUpperCase() })
                        .subscribe((reportResponse: APIResponse) => {

                            //Check if there are errors
                            if (reportResponse.errors.length) return reject(reportResponse.errors.pop());

                            //Update the old transaction
                            this._transactionsCache.add((reportResponse.data || {}).Items || {});
                            debug.log("Updated refunded transaction");

                            //Refresh the data to grab the new transaction
                            this.refresh();
                            
                            return resolve();
                        })

                }, (e) => {
                    let errorMessage = "Unknown error has occurred."
                    if (Objects.deepSearch(e, "error", "errors")
                        && Array.isArray(e.error.errors)
                        && e.error.errors.length)
                        errorMessage = e.error.errors[e.error.errors.length - 1];
                    else if (Objects.deepSearch(e, "message")
                        && "string" === typeof e.message)
                        errorMessage = e.message;
                    return reject(errorMessage)
                })

        });
    }

}

interface TransactionFilters {
    locations?: string[];
    searchText?: string;
    transactionTypes?: SaleTypes
}

interface SaleTypes {
    failure?: boolean;
    subscription?: boolean;
    singleSale?: boolean;
    loyalty?: boolean;
    refund?: boolean;
}

interface RefundOptions {
    refundAmount?: number;
    terminateSubscription?: boolean;
    terminationReason?: string;
}

class DateCache {
    private _clientCache: { [key: string]: any } = {};
    private _cache: { [key: string]: { [key: string]: any }[] } = {};
    private _getTimeStamp: (a: any) => number = (a: any) => {
        if (a.completedOn) return a.completedOn;
        else if ("renewal" == (a.transactionType || "").toLowerCase()) {
            return a["fulfillOn"] || a["timeStamp"]
        } else {
            return a["createdOn"] || a["timeStamp"]
        }
    };

    private _comparison: (a: { [key: string]: any }, b: { [key: string]: any }) => boolean = (a, b) => { return a === b };
    private _sort: (a: { [key: string]: any }, b: { [key: string]: any }) => number = (a, b): number => {
        let aTimeStamp: number = this._getTimeStamp(a);
        let bTimeStamp: number = this._getTimeStamp(b);
        if (aTimeStamp > bTimeStamp) return -1;
        if (aTimeStamp < bTimeStamp) return 1;
        return 0;
    }

    private _currentClient: string = "";

    private _cacheName: string;

    checkCurrentRecords: boolean;

    constructor(cacheName: string, checkCurrentRecordsBeforeAdding: boolean) {
        this._cacheName = cacheName;
        this.checkCurrentRecords = checkCurrentRecordsBeforeAdding;
    }

    //Set functions
    setComparisonFunction(func: (a: any, b: any) => boolean): DateCache { this._comparison = func; return this }
    setSortFunction(func: (a: any, b: any) => number): DateCache { this._sort = func; return this; }
    setTimeStampFunction(func: (a: any) => number): DateCache { this._getTimeStamp = func; return this; }


    add(data: { [key: string]: any }[], startTime?: number | string | Date, endTime?: number | string | Date): boolean {

        startTime = startTime ? Dates.toDate(startTime).getTime() : Infinity;
        endTime = endTime ? Dates.toDate(endTime).getTime() : -Infinity;

        try {

            //Loop through the provided data
            for (let i in data) {

                //Get the current element
                let element: { [key: string]: any } = data[i];

                //Get the timeStamp from the element
                let timeStamp: number | string = this._getTimeStamp(element);

                //Update the start and end times
                startTime = Math.min(startTime, Dates.toDate(timeStamp).getTime());
                endTime = Math.max(endTime, Dates.toDate(timeStamp).getTime());

                //Get the cache storage key
                let cacheStorageKey: string = Dates.toYYYYMMDD(timeStamp);

                //Get the cache records for that day
                let record: { [key: string]: any }[] = this._cache[cacheStorageKey] || [];

                //Loop through the records
                let matchesExistingRecord = false;
                if (this.checkCurrentRecords) {
                    for (let j in record) {
                        if (this._comparison(element, record[j])) {
                            //Overwrite the existing data
                            record[j] = element;

                            //Mark that we have added it
                            matchesExistingRecord = true;
                            break;
                        }
                    }
                }

                //Add to the cache if we haven't added it yet
                if (!matchesExistingRecord) record.push(element);

                //Update the cache records
                this._cache[cacheStorageKey] = record;
            }
        } catch (e) {
            debug.error(e);
            return false;
        }

        //Check if we have a start time
        if (Infinity != startTime && -Infinity != endTime) {

            //Get the start and end date
            let startDate = Dates.getDayStart(startTime);
            let endDate = Dates.getDayStart(endTime);

            //Loop through the start date to the end date
            while (!Dates.isSameDay(startDate, endDate)) {

                //Get date string
                let currentDateStr: string = Dates.toYYYYMMDD(startDate);

                //Fill with empty array if no elements are present
                this._cache[currentDateStr] = this._cache[currentDateStr] || [];

                //Go to the next day
                startDate.setDate(startDate.getDate() + 1);

            }

        }

        return true;
    }

    //TODO store ranges for requests so we can send back more accurate missing data
    getRange(start?: string | number | Date, end?: string | number | Date) {

        //Get if now provided
        end = end || Date.now();
        start = start || (new Date(2018, 1, 1, 0, 0, 0, 0)).getTime() - 604800000; //Before cwc


        let startDate: Date;
        let endDate: Date;

        //Parse the dates provided
        try {
            startDate = Dates.toDate(start);
            endDate = Dates.toDate(end);
        } catch (e) {
            debug.error(e);
            return null;
        }

        //Swap if the start date is after the end date
        if (startDate.getTime() > endDate.getTime()) {
            let tempDate: Date = startDate;
            startDate = endDate;
            endDate = tempDate;
        }

        //Prepare the response arrays
        let currentDate: Date = new Date(startDate.getTime());
        let records: any[] = [];
        let missingRecords: { start: number, end: number }[] = [];
        let missingStart: number | null = null;
        let missingEnd: number | null = null;
        let latestTimestamp: number | null = null;
        let earliestTimestamp: number | null = null;

        //Loop through the time range
        while (currentDate.getTime() < endDate.getTime() || Dates.isSameDay(currentDate, endDate)) {

            //Get the records for the day
            let currentDateStr: string = Dates.toYYYYMMDD(currentDate);
            let record: { [key: string]: any }[] | undefined = this._cache[currentDateStr];


            //Check if we didn't find any data
            if ("undefined" == typeof record) {

                //Check if this is the start of missing data
                if (null == missingStart) {
                    missingStart = null != latestTimestamp ? latestTimestamp : (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0)).getTime();
                }

            } else {

                //Update the earliest and latest timeStamps for the day
                for (let i in record) {
                    latestTimestamp = null == latestTimestamp ? this._getTimeStamp(record[i]) : Math.max(latestTimestamp, this._getTimeStamp(record[i]))
                    earliestTimestamp = null == earliestTimestamp ? this._getTimeStamp(record[i]) : Math.min(earliestTimestamp, this._getTimeStamp(record[i]))
                }

                //Check if we had missing data
                if (null != missingStart) {

                    //Update the end of the missing data
                    missingEnd = null != earliestTimestamp ? earliestTimestamp : (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0)).getTime();

                    //Add to the missing records
                    missingRecords.push({
                        start: missingStart,
                        end: missingEnd
                    });

                    //Reset the start and end
                    missingStart = missingEnd = latestTimestamp = earliestTimestamp = null;
                }

                //Add the new records
                records = [].concat(records, record);

            }

            //Go to the next day
            currentDate.setDate(currentDate.getDate() + 1);

        }

        //Check if we don't have a record for the next day
        //Check if a final missing set needs to be pushed
        if (null != missingStart || !this._cache[Dates.toYYYYMMDD(currentDate)]) {

            //Update the end of the missing data
            missingEnd = (new Date(Dates.toDate(end))).getTime();

            //Add to the missing records
            missingRecords.push({
                start: missingStart || latestTimestamp,
                end: missingEnd
            });

        }

        //Sort the records
        records.sort(this._sort);

        //Return the records
        return {
            missingRecords: missingRecords,
            records: records
        };

    }

    changeClients(clientId: string) {
        this._clientCache[this._currentClient] = this._cache;
        this._cache = this._clientCache[clientId] || {};
    }

}
