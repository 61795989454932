import { catchError, map } from 'rxjs/operators';
import { Client, ClientsService } from '@admin/clients/clients.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncStorage } from '@core/services/AsyncStorage';
import { AuthService } from '@core/services/auth/auth.service';
import { APIResponse } from '@shared/types/APIResponse';
import { Observable, throwError } from 'rxjs';
import { DebugService as debug } from "@core/services/debug.service";

@Injectable()
export class FraudMitigationService {

    private _fraudMitigationConfiguration: AsyncStorage<FraudMitigationConfiguration>;
    private _activeClientId: string;
    private _targetClientId: string;

    constructor(
        private _http: HttpClient,
        private _clientsService: ClientsService,
        private _auth: AuthService
    ) {
        this._initializeStorage();
    }

    private _initializeStorage() {
        //Prepare ICS Account storage
        this._fraudMitigationConfiguration = new AsyncStorage<FraudMitigationConfiguration>(this._http);
        this._fraudMitigationConfiguration._setAddFunction(this._saveFraudMitigationConfiguration());
        this._fraudMitigationConfiguration._setSaveActiveElementFunction(this._saveFraudMitigationConfiguration());
        this._fraudMitigationConfiguration._setUpdateFunction(this._updateFraudMitigationConfiguration());

        this._clientsService.clients.onActiveElementChange()
            .subscribe((client: Client) => {
                this._targetClientId = client.clientId;
                this._fraudMitigationConfiguration.update();
            });
    }

    public get fraudMitigationConfiguration() { return this._fraudMitigationConfiguration };
    public get activeClientId() { return this._activeClientId };

    private _saveFraudMitigationConfiguration(): (_: any, options?: any) => Observable<any> {

        let self = this;

        return (_: any, options?: any): Observable<any> => {

            //Add loyaltyPrograms
            let fraudMitigationConfiguration: FraudMitigationConfiguration = self._fraudMitigationConfiguration.activeElement;

            //Prepare the data to send
            let requestData = {
                maxDevicesPerAccount: fraudMitigationConfiguration.maxDevicesPerAccount,
                maxAccountsPerDevice: fraudMitigationConfiguration.maxAccountsPerDevice,
                clientId: self._targetClientId || self._auth.activeClient?.clientId,
                enabled: fraudMitigationConfiguration.enabled,
            };


            //Update or add the item
            return new Observable(observer => {
                this._http.post("fraudMitigation/update", requestData).toPromise()
                    .then((data: APIResponse) => {
                        if (0 == Object.keys(data.data).length || data.errors.length)
                            //Add if we get an error
                            this._http.post("fraudMitigation/add", requestData).toPromise()
                                .then((data: APIResponse) => { observer.next(data); })
                                .catch(error => { observer.next(); })
                        else
                            observer.next(data);
                    })
                    .catch(error => {
                        //Add if we get an error
                        this._http.post("fraudMitigation/add", requestData).toPromise()
                            .then((data: APIResponse) => { observer.next(data); })
                            .catch(error => { observer.next(); })
                        // observer.error(error)

                    })
            })
        }
    }

    private _updateFraudMitigationConfiguration(): () => Observable<FraudMitigationConfiguration[]> {
        let self = this;

        return () => {
            debug.log("Updating fraud mitigation configuration");

            return this._http.post("fraudMitigation/get", { clientId: self._targetClientId || self._auth.activeClient?.clientId }).pipe(
                map((response: APIResponse) => {

                    this._activeClientId = this._targetClientId;

                    //Convert the data
                    let fraudConfigurationData: FraudMitigationConfiguration = response.data || null;


                    // debug.log(fraudConfigurationData)
                    return [fraudConfigurationData];
                }),
                catchError((error:HttpErrorResponse) => {

                    if("Failed to identify single fraud mitigation config." !== error?.error?.errors?.pop())
                        return throwError(() => error);

                    //Temporary fix for invalid configurations
                    const tempFraudConfiguration: FraudMitigationConfiguration = {
                        clientId: this._targetClientId,
                        maxDevicesPerAccount: 3,
                        maxAccountsPerDevice: 1,
                        enabled: false
                    }

                    //Two-dimensional array is required for typing reasons. Don't know why, but it works properly.
                    return [[tempFraudConfiguration]]

                }));
        }
    }
}

export interface FraudMitigationConfiguration {
    clientId: string;
    maxDevicesPerAccount: number;
    maxAccountsPerDevice: number;
    enabled: boolean;
}
